import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
// import { readTime } from '../../../helpers/general';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
// import Link from '../../atoms/Link/Link';

import { Flex, Text } from '@chakra-ui/react';
import Container from '../../atoms/Container/Container';
import Icon from '../../atoms/Icon/Icon';
import * as styles from './BlogPost.module.css';
import './content.css';

const BlogPost = ({ post }) => {
  const title = post.title;
  // const slug = post.slug;
  const authorName = post.author_name;
  // const authorUri = post.author_uri;
  const date = post.published_date;
  const content = post.content;
  // const processedContent = post.contentArr;
  // const categories = post.categories || [];
  const thumbnail = post.thumbnail_path;
  const categoryUri =
    post.categories?.length > 0 ? post.categories[0].uri : 'all';
  const categoryName =
    post.categories?.length > 0 ? post.categories[0].name : 'all';

  // const categories = useMemo(() => {
  //   return post.categories || [];
  // }, [post]);

  // const timeToRead = useMemo(() => {
  //   if (content) {
  //     return readTime(content);
  //   }
  //   return 0;
  // }, [content]);

  // const categoriesTags = useMemo(() => {
  //   if (categories) {
  //     return categories.map((cat, catIdx) => (
  //       <Link url={cat.uri} key={catIdx}>
  //         {cat.name}
  //       </Link>
  //     ));
  //   }
  //   return [];
  // }, [categories]);

  const thumbnailUrl = useMemo(() => {
    if (thumbnail) {
      return thumbnail.replace('http:', 'https:');
    }

    return thumbnail;
  }, [thumbnail]);

  return (
    <Container size={'large'}>
      <Breadcrumbs
        crumbs={[
          { link: '/', label: '' },
          { link: '/blog/', label: 'Blog' },
          { link: categoryUri, label: categoryName ? categoryName : '' },
          { label: title }
        ]}
      />

      <div className={styles.blog}>
        <div className={styles.postTitle}>
          <h1 className='text-balance'>{title}</h1>
          <div className={styles.postDate}>
            {/* {categories && (
              <div className={styles.categories}>{categoriesTags}</div>
            )} */}
            <Flex color={'var(--charcoal-02);'} gap={1} wrap={'nowrap'} justifyContent={'center'} fontSize={14}>
              Written by {authorName}<Icon symbol={'paw'}/> {date}
            </Flex>
          </div>
        </div>
        <div className={styles.postImageContainer}>
          <img
            loading="lazy"
            className={styles.postImage}
            src={thumbnailUrl}
            alt={title}
          />
        </div>

        <div className={styles.postBody}>
          { content && (
            <div
              className={`${styles.postContent} post-body`}
              dangerouslySetInnerHTML={{
                __html: content
              }}
            />
          )}

          {/* {categoryName && (
            <div className={styles.viewMore}>
              <Button level="primary" href={categoryUri}>
                More {categoryName}
              </Button>
            </div>
          )} */}
        </div>
        {/* <div className={styles.socials}>
          <SocialShare
            url={`${process.env.SITE_URL}${slug}`}
            text={title}
            imageUrl={thumbnailUrl}
          />
        </div> */}
        
        <div className={styles.divider}></div>
        <Text mb={6}>Tags</Text>
        {post && post.tags && (
          <div className={styles.postTags}>
            {post.tags.map((tag, tagIndex) => (
              <span key={tagIndex} className={styles.postTag}>
                <a href={`/blog/tag/${tag.slug}`}>{tag.name}</a>
              </span>
            ))}
          </div>
        )}
        {/* {categoriesTags && (
          <div className={styles.topCategories}>{categoriesTags}</div>
        )} */}
      </div>
    </Container>
  );
};

BlogPost.propTypes = {
  post: PropTypes.shape({}).isRequired
};

export default BlogPost;
