// extracted by mini-css-extract-plugin
export var blog = "BlogPost-module--blog--7440a";
export var categories = "BlogPost-module--categories--d6dca";
export var divider = "BlogPost-module--divider--7093b";
export var mb32 = "BlogPost-module--mb-32--00233";
export var mb64 = "BlogPost-module--mb-64--45ada";
export var postBody = "BlogPost-module--postBody--eb087";
export var postContent = "BlogPost-module--postContent--ab8fd";
export var postDate = "BlogPost-module--postDate--d3b76";
export var postImage = "BlogPost-module--postImage--7f412";
export var postImageContainer = "BlogPost-module--postImageContainer--5776b";
export var postTag = "BlogPost-module--postTag--23cb0";
export var postTags = "BlogPost-module--postTags--b4249";
export var postTitle = "BlogPost-module--postTitle--e5002";
export var socials = "BlogPost-module--socials--0fa3f";
export var topCategories = "BlogPost-module--topCategories--a130f";
export var viewMore = "BlogPost-module--viewMore--02c82";