import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/organisms/Layout/Layout';
import BlogPost from '../components/organisms/BlogPost/BlogPost';
import Seo from '../components/organisms/Seo/Seo';
// import PostList from '../components/organisms/PostList/PostList';
// import CommentList from '../components/organisms/CommentList/CommentList';
import RecentPosts from '../components/organisms/RecentPosts/RecentPosts';
import { transformWpPost } from '../helpers/wordpress';

const WPPost = (props) => {
  const { data } = props;
  const { wpPost: post } = data;
  const transformedPost = transformWpPost(post);
  const categorySlug = post.categories?.nodes?.length > 0 ? post.categories.nodes[0].slug : 'all';
  const metaTitle = transformedPost.seo?.title ? transformedPost.seo?.title : `${transformedPost.title} | Blog`;
  const metaDescription = transformedPost.seo?.metaDesc ? transformedPost.seo?.metaDesc : transformedPost.excerpt;

  return (
    <Layout {...props}>
      <Seo title={metaTitle} description={metaDescription} />
      <BlogPost post={transformedPost} />
      {/* <CommentList comments={comments} postDatabaseId={post.databaseId} /> */}
      {/* {postsToRender && postsToRender.length > 0 && (
        <>
        <h6>You may also like</h6>
        <PostList posts={postsToRender} viewMode="grid" cardSize="large" options={{ clearBottom: true }} />
        </>
      )} */}
      <RecentPosts title="You might also like" category={categorySlug} />
    </Layout>
  );
};

WPPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default WPPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $categorySlug: String!) {
    wpPost(id: { eq: $id }) {
      ...PostListFields
    }
    allWpPost(
      sort: { fields: date, order: DESC }, 
      limit: 3, 
      filter: {id: {ne: $id}, categories: {nodes: {elemMatch: {slug: {eq: $categorySlug}}}}}
    ) {
      edges {
        node {
          id
          title
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              uri
              sourceUrl
            }
          }
          categories {
            nodes {
              name
              uri
              slug
            }
          }
        }
      }
    }
  }
`;
